/* .video-grid::after {
  content: "";
  flex: auto;
  width: 41.666667%;
} */

.video-player {
  max-width: 100%;
  max-height: 100%;
}
